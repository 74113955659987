import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@data-driven-forms/mui-component-mapper/text-field";
import { useNavigate, useParams } from "react-router-dom";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import { encryptText, performDDC, pick } from "../../utils/helper";
import {
  ALLOWED_PAYLOAD_KEYS,
  PAYMENT_PAYLOAD_TO_ENCRYPT,
} from "../../constants/slug.c";
import Loader from "../../components/loader/Loader.component";
import { capturePayment } from "../../components/hooks/capturePayment";
import { getApiData } from "../../components/hooks/getApiData";
import MainWrapper from "../../components/main-wrapper/MainWrapper";
import EmptyAmountPopup from "../../components/empty-amount-popup/EmptyAmountPopup";
import { applePayController } from "../../components/apple-pay-button/components/apple-pay";
import { BASE_API_URL } from "../../constants/api-path.c";
import RedirectTo3DS from "../../components/redirect-to-3ds/RedirectTo3DS";
import { isJapanese } from "../../components/hooks/isJapanese";
import useGetParameter from "../../components/hooks/useGetParameter";
import { getCardType } from "../../components/hooks/getCardType";
import { CARD_NAMES } from "../../constants/cards.c";
import { URL_PARAM } from "../../constants/url-param.c";
import HPPForm from "../../components/form/HPPForm";
import "./style.css";
import { LANGUAGES } from "../../constants/languages.c";
import {
  DasFieldListener,
  DasTextField,
  DasMaskedTextField,
  DasFormLabel,
  DasSelectOptions,
} from "../../components/form/form-fields";
import { useGoogleAnalyticsPageView, useGoogleAnalyticsClickEvent, categoryGA } from "../../components/hooks/google-analytics";
import RedirectNomupayHandler from "../../components/redirect-to-3ds/RedirectToNomupay3DS";

const HomePage = () => {
  useGoogleAnalyticsPageView(); // For google analytics
  const pushClickEventAnalytics = useGoogleAnalyticsClickEvent();  // For google analytics
  const { slug } = useParams();
  const navigate = useNavigate();
  const [cardNumber, setCardNumber] = useState("");
  const [sessionID, setSessionID] = useState(false);
  const [amountLabel, setAmountLabel] = useState(0);
  const [jwt, setJwt] = useState("");
  const [productList, setProductList] = useState([]);
  const [dasLoader, setDasLoader] = useState(true);
  const [threedsProvider, setThreedsProvider] = useState(0);
  const [htmlContent, setHtmlContent] = useState("");
  const [nomupayContent, setNomupayContent] = useState();

  const [apiData, setApiData] = useState({
    id: "",
    address: "",
    amount: 0,
    cancel_url: "",
    company: "",
    country: "",
    currency: "",
    decline_url: "",
    entity: "",
    has3DS: "",
    product: "",
    success_url: "",
    threedsProvider: "",
    hasVISA: false,
    hasMastercard: false,
    hasJCB: false,
    hasAmex: false,
    hasDinersClub: false,
    hasApplePay: false,
    hasGooglePay: false,
    billingAddress: false,
    buttons: {
      enableGooglePay: true,
      enableApplePay: true,
      enableCardPayment: true,
    },
    config: {
      modelType: null,
      hideBillingAddress: false,
      isLanguageSwitcherDisabled: false,
      sendInvoiceForTokenPayment: false,
      sendInvoiceToCustomMerchantEmail: false,
      hideShippingAddress: false,
      hidePhoneNumber: false,
      hideEmail: false,
      hideInvoice: false
    }
  });
  const DasFormSpy = () => (
    <FormSpy subscription={{ values: true }}>
      {() => (
        <DasFieldListener
          setCardNumber={setCardNumber}
          setAmountLabel={setAmountLabel}
        />
      )}
    </FormSpy>
  );

  const { currency: CURRENCY } = apiData;

  useEffect(() => {
    localStorage.removeItem("hppemail")
  }, [])

  useEffect(() => {
    getApiData(
      slug,
      setAmountLabel,
      navigate,
      setDasLoader,
      setApiData,
      setProductList,
      setSessionID,
      setJwt,
      setThreedsProvider
    );
  }, [navigate, slug]);
  const cardType = getCardType(cardNumber);

  useEffect(() => {
    switch (threedsProvider) {
      case 1:
        const cardNumberLength = cardNumber.split(" ").join("").length;
        if (
          jwt !== "" &&
          cardNumberLength === 15 &&
          cardType === CARD_NAMES.AMERICAN_EXPRESS
        ) {
          performDDC(cardNumber, jwt);
        } else if (jwt !== "" && cardNumberLength === 16) {
          performDDC(cardNumber, jwt);
        }
        break;
    }
  }, [cardNumber, threedsProvider, jwt, cardType]);

  const ComponentMapper = {
    TextField,
    DasTextField,
    DasMaskedTextField,
    DasFormLabel,
    DasSelectOptions,
    DasFormSpy,
  };
  // Condition for Iframe Component to render
  const isIFrame = useGetParameter(URL_PARAM.isIFrame);

  const handleSubmit = (values: any) => {
    pushClickEventAnalytics(categoryGA.payment, ` ${slug} : payment submitted`); // For google analytics
    setDasLoader(true);
    let data = {
      ...values,
      monthyear: japanese ? values?.monthyear?.value : values?.monthyear
    };
    if (jwt !== "") {
      performDDC(data.cardnumber, jwt);
    }
    data.amount = data.amount.toString();
    data.cardnumber = data.cardnumber.replaceAll(" ", "");
    data.dfReferenceId = sessionID;
    data.URLKey = slug;

    data.billing_address = {
      address_line1: data.address1,
      city: data.city,
      email: data.email,
      phone: data.phone,
      postal_code: data?.postal_code || "00000", // by default zero for HK
      state: data.state || '',
      country: data.country.value,
    };
    data.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //encrypting text
    for (let k in data) {
      if (PAYMENT_PAYLOAD_TO_ENCRYPT.includes(k)) {
        data[k] = encryptText(data[k]);
      }
    }

    let allowedPayloadData: any = pick(data, ALLOWED_PAYLOAD_KEYS);
    capturePayment(
      allowedPayloadData,
      sessionID,
      navigate,
      setHtmlContent,
      setNomupayContent,
      setDasLoader,
      slug,
      isIFrame
    );
  };
  const [amountPopup, setAmountPopup] = useState({
    open: false,
    logo: "",
    onContinueClick: () => { },
    type: "",
  });

  const handleApplePayClick = () => {
    pushClickEventAnalytics(categoryGA.applePay, ` ${slug} : payment submitted`); // For google analytics
    applePayController.setSlug(slug);
    applePayController.setBackendValidateURL(
      `${BASE_API_URL}/applepay/sessions/${slug}`
    );
    applePayController.setConfig(apiData);
    applePayController.setNavigate(navigate);
    applePayController.applyButtonClickListener();
  };
  const hasGooglepayOrApplePay = apiData.hasApplePay || apiData.hasGooglePay;

  const japanese = isJapanese();
  useEffect(() => {
    localStorage.setItem("lang", japanese ? LANGUAGES.JP : LANGUAGES.EN);
  }, [japanese]);

  const isIbec = apiData && apiData?.config?.modelType === 1 ? true : false;


  const allTrue = Object.values(apiData?.buttons).every(value => value === true);

  const showOnlyHPPForm = apiData?.buttons?.enableCardPayment;

  if (dasLoader) {
    return <Loader />;
  } else if (nomupayContent) {
    return <RedirectNomupayHandler redirect={nomupayContent} />;
  }
  else if (htmlContent) {
    return <RedirectTo3DS htmlContent={htmlContent} />;
  } else if (isIFrame) {
    return (
      <div className="iframe-home">
        <HPPForm
          hasGooglepayOrApplePay={hasGooglepayOrApplePay}
          CURRENCY={CURRENCY}
          apiData={apiData}
          ComponentMapper={ComponentMapper}
          handleSubmit={handleSubmit}
        />
      </div>
    );
  } else {
    return (
      <>
        {amountPopup.open && (
          <EmptyAmountPopup
            amountPopup={amountPopup}
            heading={"merchant.Empty_Popup_Please_Enter_An_Amount"}
            buttonText={"Continue"}
            apiData={apiData}
            setApiData={setApiData}
            setAmountPopup={setAmountPopup}
            handleApplePayClick={handleApplePayClick}
            slug={slug}
            setDasLoader={setDasLoader}
            navigate={navigate}
          />
        )}
        <MainWrapper
          isIbec={isIbec}
          apiData={apiData}
          setApiData={setApiData}
          setAmountPopup={setAmountPopup}
          handleApplePayClick={handleApplePayClick}
          slug={slug}
          setDasLoader={setDasLoader}
          navigate={navigate}
          productList={productList}
          allTrue={allTrue}
          showOnlyHPPForm={showOnlyHPPForm}
        >
          <Grid className={"form-list-without-wrap"} item xs={6}>
            <div className="boxwrapper">
              <HPPForm
                hasGooglepayOrApplePay={hasGooglepayOrApplePay}
                CURRENCY={CURRENCY}
                apiData={apiData}
                ComponentMapper={ComponentMapper}
                handleSubmit={handleSubmit}
              />
            </div>
          </Grid>
        </MainWrapper>
      </>
    );
  }
};

export default HomePage;
