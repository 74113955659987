import React, { useContext, useEffect } from "react";

import Details from "../details/Details";
import ApplePayButton from "../apple-pay-button/ApplePayButton";
import DasCustomGpayButton from "../google-pay-button/DasCustomGpayButton";
import { DasGooglePayButton } from "../google-pay-button/DasGooglePayButton";
import { useLocation, useNavigate } from "react-router-dom";
import DasORSeparator from "../apple-pay-button/components/DasORSeparator";
import HppWrapper from "./HppWrapper";
import { applePayController } from "../apple-pay-button/components/apple-pay";
import { useTranslation } from "react-i18next";
import { getCurrencyIconImg } from "../../utils/helper";
import Loader from "../loader/Loader.component";
// import PciLogo from "../apple-pay-button/components/PciLogo";

const LangWrapper = ({
  isIbec = false,
  apiData,
  children,
  pblProduct,
  subscriptionProduct,
  setApiData,
  setAmountPopup,
  handleApplePayClick,
  slug,
  setDasLoader,
  showOnlyHPPForm = false,
  allTrue
}: any) => {
  const { address: ADDRESS, company: COMPANY } = apiData;
  const navigate = useNavigate();
  useEffect(() => {
    setDasLoader(true)
    let transactioContext: any = localStorage.getItem('transactionContext');
    try {
      transactioContext = JSON.parse(transactioContext)
    } catch (error) {

    }
    if (transactioContext) {
      if (isIbec) {
        const redirect_url = transactioContext.return_url.decline_url;
        localStorage.removeItem('transactionContext');
        if (redirect_url && localStorage.getItem("slug")===slug)
          window.location.href = redirect_url;
      } else {
        localStorage.removeItem('transactionContext');
      }
    }
    setDasLoader(false)
  }, []);

  const GpayApplePayAlignment = isIbec && applePayController.isAppleSessionAvailable() && apiData?.hasApplePay && apiData?.hasGooglePay
    ? "vertical-alignment" : "";

  const AMOUNT = `${apiData?.amount}`;

  const hideFormClass = !allTrue ? "hide-form" : "";

  const { t } = useTranslation();
if(localStorage.getItem('transactionContext') && isIbec && localStorage.getItem("slug")===slug){
  return <>
  <Loader/>
  </>
}
  return (
    <HppWrapper apiData={apiData}
      allTrue={allTrue}
    >
      {/*Product details */}
      <div className="flex-section">
        <div className="additional-product">
          {subscriptionProduct && subscriptionProduct}
          {pblProduct && pblProduct}
        </div>
        <div className="form-div">
          <Details
            COMPANY={COMPANY || ""}
            ADDRESS={ADDRESS || ""}
          // CURRENCY={CURRENCY || ""}
          // amountLabel={amount || 0}
          />
          {/* Pay Buttons */}

          {
            !allTrue && <>
              <div className="show-only-apple-pay-and-google-pay-button">
                {/* Amount to pay */}
                <label>
                  {t("merchant.Payment_Information.fields.Amount")}
                  </label>
                <div className="gpay-appay-amount-div">


                  <div style={{ height: "3rem", width: "100%", background: "#f2f2f2", borderRadius: "50px", border: "1px solid #dedede", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 20px", position:"relative" }}>
                    <span>
                      {AMOUNT}
                    </span>
                    <span>

                      {
                        ["JPY", "SGD", "HKD", "USD"].includes(apiData?.currency) ?
                          <img className="currency-img-icon" src={getCurrencyIconImg(apiData?.currency)} alt={apiData?.currency} /> :
                          <p>
                            {apiData?.currency}
                          </p>
                      }

                    </span>
                    <span className="inputcheck"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#ccc" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path></svg></span>
                  </div>

                </div>
              </div>
              {/* <PciLogo /> */}
            </>
          }

          <div
            className={

              apiData?.product?.length > 0
                ? `wallet-buttons main-sep pbl-sep ${GpayApplePayAlignment} ${hideFormClass}`
                : `wallet-buttons main-sep ${GpayApplePayAlignment} ${hideFormClass}`
            }
          >

            {
              isIbec && allTrue  && <div className={allTrue?"ibec-google-pay-apple-pay-title-text": "ibec-google-pay-apple-pay-title-text mb-25"}>
                <DasORSeparator
                  text={"From The Button Below"}
                  text2={"You Can Also Pay"}
                />
              </div>
            }
            {
              isIbec && !allTrue && apiData?.hasApplePay && apiData?.buttons?.enableApplePay && 
              <div className={allTrue?"ibec-google-pay-apple-pay-title-text": "ibec-google-pay-apple-pay-title-text mb-25"} dangerouslySetInnerHTML={{ __html: t("Enable Apple Pay Text") }} />
              // <div className="ibec-google-pay-apple-pay-title-text">
              //   {t("Enable Apple Pay Text")}
              // </div>
            }
            {
              isIbec && !allTrue && apiData?.hasGooglePay && apiData?.buttons?.enableGooglePay && 
              <div className={allTrue?"ibec-google-pay-apple-pay-title-text": "ibec-google-pay-apple-pay-title-text mb-25"} dangerouslySetInnerHTML={{ __html: t("Enable Google Pay Text") }} />
              // <div className="ibec-google-pay-apple-pay-title-text">
              //   {t("Enable Google Pay Text")}
              // </div>
            }
            <div className="wallet-apple-google-pay-buttons">

 {/* this is -----Apple Pay Button------ */}
  {/* {(isIbec && (!allTrue && apiData?.hasApplePay && apiData?.buttons?.enableApplePay) ||  (!isIbec && apiData?.hasApplePay)) && ( */}
  {(apiData?.hasApplePay && apiData?.buttons?.enableApplePay) && ( 
                  <ApplePayButton
                  apiData={apiData}
                  setApiData={setApiData}
                  setAmountPopup={setAmountPopup}
                  handleApplePayClick={handleApplePayClick}
                />
                )}




              {/* this is -----Apple Pay Button------ */}
              {/* {apiData?.hasApplePay && apiData?.buttons?.enableApplePay ? (
                <ApplePayButton
                  apiData={apiData}
                  setApiData={setApiData}
                  setAmountPopup={setAmountPopup}
                  handleApplePayClick={handleApplePayClick}
                />
              ) : (
                ""
              )} */}

                {/* this is -----Google Pay Button------ */}
                {/* {(isIbec && (!allTrue && apiData?.hasGooglePay && apiData?.buttons?.enableGooglePay) ||  (!isIbec && apiData?.hasGooglePay)) && ( */}
                {(apiData?.hasGooglePay && apiData?.buttons?.enableGooglePay) && ( 
                 
                 <>
                    {
                      !apiData?.amount ? (
                        <DasCustomGpayButton
                          slug={slug}
                          apiData={apiData}
                          setAmountPopup={setAmountPopup}
                        />
                      ) : (
                        <DasGooglePayButton
                          apiData={apiData}
                          slug={slug}
                          navigate={navigate}
                          setDasLoader={setDasLoader}
                        />
                      )
                    }
                  </>
                )}


{/* 
              {apiData?.hasGooglePay && apiData?.buttons?.enableGooglePay ? (
                <>
                  {!apiData?.amount ? (
                    <DasCustomGpayButton
                      slug={slug}
                      apiData={apiData}
                      setAmountPopup={setAmountPopup}
                    />
                  ) : (
                    <>
                      <DasGooglePayButton
                        apiData={apiData}
                        slug={slug}
                        navigate={navigate}
                        setDasLoader={setDasLoader}
                      />
                    </>
                  )}
                </>
              ) : (
                ""
              )} */}
            </div>
            {allTrue && (apiData?.hasGooglePay || (apiData?.hasApplePay &&  applePayController.isAppleSessionAvailable()) ) && (
              <DasORSeparator />
            )}
          </div>

          {allTrue && children}
        </div>
      </div>

      {/* subscription details */}
    </HppWrapper>
  );
};

export default LangWrapper;
